export const useLocalUser = () => {
  const cookieLocalUserId = useCookie("localUserId")

  const getLocalUserId = (): string => {
    // SSRでは使用不可
    if (!process.client) return ""

    // localUserIdがlocalstorageにある場合はそっちを使う、またcookieに反映させる
    // iOSは7日間アクセスがないとlocalstorageが消えしまうが、そのほかの場合は消えないので、localstorageから取得する処理はしばらく残した方がいい
    const localUserId = localStorage.getItem("localUserId")
    if (localUserId) {
      if (cookieLocalUserId.value !== localUserId) {
        cookieLocalUserId.value = localUserId
      }
    }

    if (!cookieLocalUserId.value) {
      console.error("localUserId is not set")
      waitForNREUM().then((nreum) => nreum.noticeError("localUserId is not set"))
    }

    return cookieLocalUserId.value || ""
  }

  return {
    getLocalUserId,
  }
}
